import React, { Component } from 'react'
import * as emailjs from 'emailjs-com'
//import Layout from '../components/layout'
import { Button, Col, 
  FormFeedback, 
  Form, FormGroup, InputGroupAddon, InputGroup,
  Label, Input, InputGroupText, Row } from 'reactstrap'
class ContactForm extends Component {
  state = {
    name: '',
    email: '',
    subject: '',
    message: '',
  }
handleSubmit(e) {
    e.preventDefault()
    const { name, email, subject, message } = this.state
    let templateParams = {
      from_name: name,
      email: email,
      to_name: 'Customer Service',
      subject: subject,
      message_html: message,
     }
     emailjs.send(
      'service_3z8bls3',
      'template_eapltd3',
       templateParams,
      'user_2d4xpo29QEBleTzc5f2Uq'
     )
     this.resetForm()
 }
resetForm() {
    this.setState({
      name: '',
      email: '',
      subject: '',
      message: '',
    })
  }
handleChange = (param, e) => {
    this.setState({ [param]: e.target.value })
  }
render() {
    return (
      <>
      <Col className="ml-auto mr-auto" md="8">
        <div>
          <h1 className="p-heading1, text-center" >Contact Us</h1>
          <Form onSubmit={this.handleSubmit.bind(this)}>
          <Row>
          <Col md="6">
                <FormGroup controlId="formBasicName">
                  <Label className="text-muted">Name</Label>
                  <InputGroup>
                  <InputGroupAddon addonType="prepend">
                        <InputGroupText>
                            <i className="nc-icon nc-single-02" />
                          </InputGroupText>
                      </InputGroupAddon>
                  <Input
                    type="text"
                    name="name"
                    value={this.state.name}
                    className="text-primary"
                    onChange={this.handleChange.bind(this, 'name')}
                    placeholder="Name"
                  />
                  </InputGroup>
                </FormGroup>
              </Col>
            <Col md="6">
                <FormGroup controlId="formBasicEmail">
                  <Label className="text-muted">Email address</Label>
                  <InputGroup>
                  <InputGroupAddon addonType="prepend">
                        <InputGroupText>
                            <i className="nc-icon nc-email-85" />
                          </InputGroupText>
                      </InputGroupAddon>
                  <Input
                    type="email"
                    name="email"
                    value={this.state.email}
                    className="text-primary"
                    onChange={this.handleChange.bind(this, 'email')}
                    placeholder="Enter email"
                  />
                 </InputGroup>
                </FormGroup>
          </Col>
            </Row>
<FormGroup controlId="formBasicSubject">
              <Label className="text-muted">Subject</Label>
              <InputGroup>
                  <InputGroupAddon addonType="prepend">
                        <InputGroupText>
                            <i className="nc-icon nc-email-85" />
                          </InputGroupText>
                      </InputGroupAddon>
              <Input
                type="text"
                name="subject"
                className="text-primary"
                value={this.state.subject}
                onChange={this.handleChange.bind(this, 'subject')}
                placeholder="Subject"
              />
              </InputGroup>
            </FormGroup>
<FormGroup controlId="formBasicMessage">
              <Label className="text-muted">Message</Label>
              <InputGroup>
                  <InputGroupAddon addonType="prepend">
                        <InputGroupText>
                            <i className="nc-icon nc-single-copy-04" />
                          </InputGroupText>
                      </InputGroupAddon>
              <Input
                type="textarea"
                name="message"
                placeholder="What would you like to inquire about..."
                className="text-primary"
                value={this.state.message}
                onChange={this.handleChange.bind(this, 'message')}
              />
              </InputGroup>
            </FormGroup>
            <Row>
                    <Col className="ml-auto mr-auto" md="4">
                      <Button variant="primary" type="submit" color="danger" size="lg">
                          Send Message
                      </Button>
                    </Col>
            </Row>
          </Form>
        </div>
        </Col>
      </>
    )
  }
}
export default ContactForm