
import React from "react";

// reactstrap components
import {
  Button,
  Card,
  CardBody,
  CardFooter,
  CardTitle,
  Form,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Container,
  Row,
  Col,
} from "reactstrap";

// core components
import ExamplesNavbar from "components/Navbars/ExamplesNavbar.js";
import LandingPageHeader from "components/Headers/LandingPageHeader.js";
import DemoFooter from "components/Footers/DemoFooter.js";
//import Contact from "components/ContactForm";
import ContactForm from "components/ContactForm";

//function LandingPage() {
  function Index() {
  document.documentElement.classList.remove("nav-open");
  React.useEffect(() => {
    document.body.classList.add("index");
    return function cleanup() {
      //document.body.classList.remove("profile-page");
      document.body.classList.remove("index");
    };
  });
  return (
    <>
      <ExamplesNavbar />
      <LandingPageHeader />
      <div className="main">
        <div className="section text-center">
          <Container>
            <Row>
              <Col className="ml-auto mr-auto" md="8">
                <h2 className="title">Let's talk</h2>
                <h5 className="description">
                In the fast paced world of technology, what 
                sets us apart is that we are driven to help our partners
                be successful.  We will assist with the presentation,
                management and security of your most valuable asset - your Data
                 - this valuable asset that drives nearly all aspects of 
                 your business.  It is as true now as it was in days of yore -
                 Information is power.
                </h5>
                <br />
                
              </Col>
            </Row>
            <br />
         
          </Container>
        </div>
        <div className="section section-dark text-center">
          <Container>
            <h2 className="title">Let's talk about us</h2>
            <Row>
              <Col md="4">
                <Card className="card-profile card-plain">
                  <div className="card-avatar">
                 
                  </div>
                  <CardBody>
                    <a href="#pablo" onClick={(e) => e.preventDefault()}>
                      <div className="author">
                        <CardTitle tag="h4">
                        <i className="nc-icon nc-album-2" />
                        </CardTitle>
                        
                        <h6 className="card-category">Reach New Heights</h6>
                      </div>
                    </a>
                    <p className="card-description text-center">
                    Spend your time generating new ideas. That your business doesn't have to
                      worry about of implementing, leave that to us.

                      Teamwork is so important that it is the cornerstone of all successful 
                      workplace envirionments and therefore business.
                    </p>
                  </CardBody>
                 
                </Card>
              </Col>
              <Col md="4">
                <Card className="card-profile card-plain">
                  <div className="card-avatar">
                    <a href="#pablo" onClick={(e) => e.preventDefault()}>
                      
                    </a>
                  </div>
                  <CardBody>
                    <a href="#pablo" onClick={(e) => e.preventDefault()}>
                      <div className="author">
                        <CardTitle tag="h4">
                        <i className="nc-icon nc-bulb-63" />
                        </CardTitle>
                        <h6 className="card-category">New Ideas</h6>
                      </div>
                    </a>
                    <p className="card-description text-center">
                      A group becomes a team when each member is sure enough of
                      himself and his contribution to praise the skill of the
                      others. 
                      
                    </p>
                  </CardBody>
                  
                  <CardFooter className="text-center">
                    
                  </CardFooter>
                </Card>
              </Col>
              <Col md="4">
                <Card className="card-profile card-plain">
                  <div className="card-avatar">
                    <a href="#pablo" onClick={(e) => e.preventDefault()}>
                      
                    </a>
                  </div>
                  <CardBody>
                    <a href="#pablo" onClick={(e) => e.preventDefault()}>
                      <div className="author">
                        <CardTitle tag="h4"> <i className="nc-icon nc-chart-bar-32" /></CardTitle>
                        <h6 className="card-category">Data Driven Development</h6>
                      </div>
                    </a>
                    <p className="card-description text-center">
                      The strength of the team is each individual member. The
                      strength of each member is the team. The same holds true for your data,
                      - maximizing the amount and utility of your data can be key to 
                      enhancing the revenue streams for a business.
                    </p>
                  </CardBody>
                  <CardFooter className="text-center">
                
                  </CardFooter>
                </Card>
              </Col>
            </Row>
          </Container>
        </div>
        <ContactForm />
        <div className="section landing-section">
          
        </div>
      </div>
      <DemoFooter />
    </>
  );
}
export default Index
//export default LandingPage;
